//import FormCadastroComponent from "./FormCadastroComponent.vue";
//import FormLoginComponent from "./FormLoginComponent.vue";



import { mapActions } from "vuex";

export default {
    //components: { FormCadastroComponent, FormLoginComponent },
    data: () => ({
        valid: true,
        loading: false,
        login: true,

        tabsForm: null,
        show1: false,
        snackbar: false,
        text: '',
        timeout: 2000,
        form: {
            email: null,
            password: null,
        },
        senhaRules: [
            (v) => !!v || "Senha é obrigátoria",
            (v) => v.length >= 8 || "Mínimo de 8 caracteres",
        ],
        emailRules: [
            (v) => !!v || "E-mail obrigatório",
            (v) => /.+@.+\..+/.test(v) || "E-mail informado não é válido",
        ],
    }),
    created() {

        if (this.$route.query.validacao == 1) {
            this.$swal({
                toast: false,
                position: "center",
                showConfirmButton: true,
                icon: 'success',
                confirmButtonColor: "#2778c4",
               
                title: 'Cadastro validado com sucesso',
            });
        }
        if (this.$route.query.validacao == 2) {
            this.$swal({
                toast: false,
                position: "center",
                showConfirmButton: true,
                icon: 'error',
                confirmButtonColor: "#2778c4",
                title: 'Link de verificação expirado, estamos enviando um novo para o seu email.',
            });
        }
        if (this.$route.query.validacao == 3) {
            this.$swal({
                toast: false,
                position: "center",
                showConfirmButton: true,
                icon: 'info',
                confirmButtonColor: "#2778c4",
                title: 'Cadastro já verificado anteriormente.',
            });
        }
    },

    methods: {
        ...mapActions("auth", ["ActionDoLogin"]),
        async submit() {
            this.loading = true
            this.login = false

            try {
                await this.ActionDoLogin(this.form);

                if (this.$store.state.auth.user.empresas.length > 0) {
                    this.$router.push({ name: "selecionarEmpresa" });
                } else {

                    this.$router.push({ name: "Bem Vindo" });
                }
            } catch (error) {
                this.loading = false
                this.login = true
                this.text = error.data ? error.data.message : "Não foi possivel fazer login";
                this.snackbar = true

            }
            //// this.$refs.form.validate()
        },
        cadastrar() {

            this.$router.push({ name: "cadastrar" });
        },
        recuperarAcesso() {

            this.$router.push({ path: "recuperaracesso" });
        }
    },
};


